<template>
    <div class="info-content">
        <div>
            <el-row class="info-row">
                <el-col :span="5" class="info-title">比赛时间</el-col>
                <el-col :span="18">{{$moment(info.started_at).format("YYYY-MM-DD")}}&nbsp;{{info.started_time}}</el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="5" class="info-title">比赛地点</el-col>
                <el-col :span="18">{{info.address}}</el-col>
            </el-row>
            <!--<el-row class="info-row">
                <el-col :span="5" class="info-title">费用</el-col>
                <el-col :span="18">{{info.payment}}</el-col>
            </el-row>-->
            <el-row class="info-row">
                <el-col :span="5" class="info-title">主办方</el-col>
                <el-col :span="18">{{info.organizer.show_name}}</el-col>
            </el-row>
            <!--<el-divider >比赛简介</el-divider>
            <el-row class="info-row">
                <el-col :span="5" class="info-title">赛制</el-col>
                <el-col :span="18">瑞士轮{{info.swiss_rounds}}进{{info.finals}}</el-col>
            </el-row>
            <el-row class="info-row">
                <el-col :span="5" class="info-title">奖品</el-col>
                <el-col :span="18"><pre>{{info.prize}}</pre></el-col>
            </el-row>-->
            <el-row class="info-row">
                <el-col :span="5" class="info-title">联系方式</el-col>
                <el-col :span="18">{{info.organizer.phone}}</el-col>
            </el-row>
            <!--<el-row class="info-row">
                <el-col :span="5" class="info-title">备注</el-col>
                <el-col :span="18">
                    <pre>{{info.desc}}</pre>
                </el-col>
            </el-row>-->
        </div>
    </div>
</template>

<script>
    export default {
        name: "tournament-info",
        props: {
            info: {
                type: Object,
                default: function () {
                    return {};
                }
            },
        },
    }
</script>

<style scoped>
    .info-content{
        margin:0 auto;
        width: 60%;
        letter-spacing: 2px;
        font-size: 16px;
        color: #636b6f;
    }
    .info-title{
        font-weight: bold;
    }
    .info-row{
        min-height: 40px;
        line-height: 40px;
        padding: 15px;
    }

</style>
